@import url('https://fonts.googleapis.com/css2?family=Hammersmith+One&display=swap');

body {
    text-align: center;
    background: rgb(240,91,37);
    background: linear-gradient(90deg, rgba(240,91,37,1) 0%, rgba(251,176,59,1) 100%);

}
.logo {
    display: block;
    margin-top: 25vh;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: auto;
  max-width: 350px;
  width: 55vw;
  border-radius: 50%;

}
.description {
    font-family: 'Hammersmith One', sans-serif;
    max-width: 650px;
    margin-left: auto;
    margin-right: auto;
    color: white;
}